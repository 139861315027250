import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const cToken = "insuranceAdminToken";
const cUserDetail = "insuranceAdminUserDetail";

// ------------------------------------ Set in Cookies and localstorage ------------------------------------

export function setTokenInCookie(token) {
  // Cookies.set(cToken, token);
  localStorage.setItem(cToken, token);
}

export function setUserDetailInCookie(userDetail) {
  // Cookies.set(cUserDetail, userDetail);
  localStorage.setItem(cUserDetail, JSON.stringify(userDetail));
}

// ------------------------------------ Get in Cookies and localstorage ------------------------------------

export function getTokenFromCookie() {
  const token = localStorage.getItem(cToken);
  // const token = Cookies.get(cToken);
  if (token === undefined || token === null) {
    return;
  } else {
    return token;
  }
}

export function getUserDetailFromCookie() {
  const userDetail = localStorage.getItem(cUserDetail);
  // const userDetail = Cookies.get(cUserDetail);

  if (userDetail === undefined || userDetail === null) {
    return;
  } else {
    return JSON.parse(userDetail);
  }
}

// ------------------------------------ Remove in Cookies and localstorage ------------------------------------

export function removeCookie() {
  // Cookies.remove(cToken);
  // Cookies.remove(cUserDetail);

  // remove
  // localStorage.removeItem('myData');
  // remove all
  localStorage.clear();
}

// ------------------------------------ Cookies and localstorage utility methods ------------------------------------

export function isLoggedIn() {
  const token = localStorage.getItem(cToken);
  // const token = Cookies.get(cToken);
  if (token === undefined || token === null) {
    return false;
  } else {
    return true;
  }
}

// -------------------------------------------------------------------------------------------------
