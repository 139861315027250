import keyMirror from "fbjs/lib/keyMirror";

export const ActionTypes = keyMirror({
  FETCH_INITIATED: undefined,
  FETCH_COMPLETED: undefined,

  UNAUTHENTICATED_REQUEST: undefined,

  USER_LOGOUT: undefined,

  UPDATE_IS_AUTHENTICATED: undefined,

  BUSINESS_EXCEPTION: undefined,
});
