import React, { Component } from "react";
// import "../../styles/modal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ModalBox extends Component {
  showSuccessBtn = (props) => {
    if (!props.success) return;
    return (
      <button
        onClick={(e) => {
          this.dismissModal();
          props.onSuccessClick(e);
        }}
        className="loginPopup-Btn"
        data-dismiss="modal"
        style={{
          backgroundColor: "rgb(51, 122, 183)",
          borderColor: "rgb(46, 109, 164)",
        }}
      >
        {props.success}
      </button>
    );
  };

  showCancelBtn = (props) => {
    if (!props.cancel) return;
    return (
      <button
        className="loginPopup-closeBtn"
        onClick={(e) => {
          this.dismissModal();
          props.onCancelClick(e);
        }}
        data-dismiss="modal"
      >
        {props.cancel}
      </button>
    );
  };

  showModal = () => {
    // $('#loginPopup').modal('show');
    toast.configure();
    toast.error("Something went wrong.");
  };

  dismissModal = () => {
    // $('#loginPopup').modal('hide');
    // $(".modal-backdrop").remove();
  };

  componentDidMount() {
    this.showModal();
  }

  componentWillUnmount() {
    this.dismissModal();
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="modal vertically-modal "
          id="loginPopup"
          style={{ margin: "15px", paddingRight: "17px" }}
        >
          <div className="modal-dialog modal-md">
            <div
              className="modal-content ml-auto mr-auto"
              style={{ backgroundColor: "#fff", border: "none", width: "75%" }}
            >
              <div className=" row no-gutters justify-content-left">
                <div className="col-12 text-left">
                  <div className="loginPopup-header"> {this.props.title} </div>
                </div>
                <div className="col-12 text-left" style={{ marginTop: "15px" }}>
                  <div className="loginPopup-infoTest">
                    {" "}
                    {this.props.content}{" "}
                  </div>
                </div>
                <div
                  className="col-12 text-right"
                  style={{ marginTop: "15px" }}
                >
                  {this.showSuccessBtn(this.props)}
                  <span style={{ paddingLeft: "10px" }}>
                    {this.showCancelBtn(this.props)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
