import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "redux-inject-reducer-and-saga";

import rootSaga from "../sagas/root";
import rootReducer from "../reducer/root";

import middleware, { sagaMiddleware } from "./middleware";
const reducer = persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    whitelist: ["app", "user"],
  },

  combineReducers({ ...rootReducer })
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("../reducer/root", () => {
      store.replaceReducer(require("../reducer/root").default);
    });
  }
  //return {store};

  return {
    persistor: persistStore(store),
    store,
  };
};

//const { store, persistor } = configStore();
const store = configureStore();

global.store = store;

//

export { store };
