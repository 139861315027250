import { createSelector } from "reselect";

const selectorState = (state) => state.get("AppPage");

export const isUserAuthenticated = () =>
  createSelector(selectorState, (bstate) => bstate.isUserAuthenticated);

export const isLoading = () =>
  createSelector(selectorState, (bstate) => {
    return bstate.fetchInitiated === true && bstate.fetchCompleted === false;
  });

export const getBusinessException = () =>
  createSelector(selectorState, (bstate) => bstate.businessException);
