import { ActionTypes } from "../constant/constant";
import { isLoggedIn } from "../../utility/session-utils";

const initialState = {
  fetchInitiated: false,
  fetchCompleted: true,

  isUserAuthenticated: isLoggedIn(),

  businessException: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_INITIATED: {
      state.fetchInitiated = true;
      state.fetchCompleted = false;
      return { ...state };
    }
    case ActionTypes.FETCH_COMPLETED: {
      state.fetchInitiated = false;
      state.fetchCompleted = true;
      return { ...state };
    }

    case ActionTypes.UNAUTHENTICATED_REQUEST: {
      state.isUserAuthenticated = false;
      return { ...state };
    }

    case ActionTypes.UPDATE_IS_AUTHENTICATED: {
      state.isUserAuthenticated = action.payload.isUserAuthenticated;
      return { ...state };
    }

    case ActionTypes.BUSINESS_EXCEPTION: {
      state.businessException = action.payload.exception;
      return { ...state };
    }

    default:
      return { ...state };
  }
}
