import fetchDefaults from "fetch-defaults";
import FetchInterceptor from "./fetch-interceptor";
import { store } from "../configuration/store";
import * as configConstants from "../configuration/constant/configConstants";
import {
  getUnauthenticatedRequestAction,
  getFetchCompletedAction,
} from "../configuration/actions/loading";
import { getBusinessException } from "../configuration/actions/root";
import { getTokenFromCookie } from "./session-utils";

export const asResponse = (response) =>
  Promise.resolve({
    status: response.status,
    ok: response.ok,
    json: response.json(),
  });

const fetchInProgress = [];
// Register interceptor hooks
const interceptor = FetchInterceptor.register({
  onBeforeRequest(request, controller) {
    fetchInProgress.push(1);
  },
  onRequestSuccess(response, request, controller) {
    hideProgressBar();
  },
  onRequestFailure(response, request, controller) {
    hideProgressBar();

    const responseStatus = (response && response.status) || 0;
    if (responseStatus === 401 || responseStatus === 403) {
      store.dispatch(getUnauthenticatedRequestAction());
    } else if (responseStatus && responseStatus === 400) {
      dispatchException(response, getBusinessException, true);
    } else if (responseStatus && responseStatus === 500) {
      // store.dispatch(
      //   getBusinessException({ recoverable: false, errorCode: 9999 })
      // );
    }
  },
  retryRequest(request) {
    return apiFetch(request);
  },
});

const dispatchException = (response, errorAction, recoverable) => {
  try {
    const result = response.json();
    response.json = function () {
      return result;
    };
    result.then((item) => {
      item.recoverable = recoverable;
      store.dispatch(errorAction(item));
    });
  } catch (e) {
    console.log(e);
  }
};

const hideProgressBar = () => {
  fetchInProgress.pop();
  const progressCount = fetchInProgress.length;
  console.log(
    `onRequestSuccess${fetchInProgress.length}, progressCount ${progressCount}`
  );
  setTimeout(() => {
    console.log(
      `onTimeout${fetchInProgress.length}, progressCount ${progressCount}`
    );
    if (progressCount === fetchInProgress.length)
      store.dispatch(getFetchCompletedAction());
  }, 300);
};

const api = { url: configConstants.SERVER_ADDR };
export const initApi = function (url) {
  if (url) api.url = url;
};

//-----------------------------------------------------------

export const apiFetch = function (url, data) {
  const serverUrl = api.url;
  return fetchDefaults(window.fetch, serverUrl, {
    headers: getAuthHeader(),
  })(url, data);
};

function getAuthHeader() {
  const token = getTokenFromCookie();

  if (token === undefined || token === null) {
    return {
      "Content-type": "application/json",
    };
  } else {
    return {
      Authorization: ` Bearer ${token}`,
      "Content-type": "application/json",
    };
  }
}

//-----------------------------------------------------------

export const apiFetchWithMultipart = function (url, data) {
  const serverUrl = api.url;
  return fetchDefaults(window.fetch, serverUrl, {
    headers: getAuthWithMultipartHeader(),
  })(url, data);
};

function getAuthWithMultipartHeader() {
  const token = getTokenFromCookie();

  if (token === undefined || token === null) {
    return {
      // "Content-type": "multipart/form-data",
    };
  } else {
    return {
      Authorization: ` Bearer ${token}`,
      // "Content-type": "multipart/form-data",
    };
  }
}

//-----------------------------------------------------------

export const apiFetchWithAdminData = function (url, data, token) {
  const serverUrl = api.url;
  return fetchDefaults(window.fetch, serverUrl, {
    headers: getAuthHeaderAdmin(token),
  })(url, data);
};

function getAuthHeaderAdmin(token) {
  return {
    Authorization: ` Bearer ${token}`,
    "Content-type": "application/json",
  };
}

//-----------------------------------------------------------
