import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  consumeException,
  isConsumed,
  getResponseError,
} from "./utility/error-utils";

import * as actions from "./configuration/actions/root";
import reducer from "./configuration/reducer/root";
import saga from "./configuration/sagas/root";
import * as selectors from "./configuration/selector/root";

import { createStructuredSelector } from "reselect";
import { injectReducer, injectSaga } from "redux-inject-reducer-and-saga";
import { connect } from "react-redux";
import { compose } from "redux";

import { store } from "./configuration/store";
import RouterComponent from "./router";
import History from "./utility/history-utils";
import Loader from "./components/Helper/Loader";
import ModalBox from "./configuration/ModalBox";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  showLoading = () => {
    if (this.props.isLoading) {
      return <Loader />;
    }
  };

  //   showError = () => {
  //     if (
  //       this.props.exception &&
  //       !isConsumed(this.props.exception)
  //       //  && this.errorCodes.indexOf(this.props.exception.errorCode) != -1
  //     ) {
  //       return (
  //         <ModalBox
  //           title="Error"
  //           success="Ok"
  //           content={getResponseError(this.props.exception)}
  //           onSuccessClick={() => {}}
  //           onCancelClick={() => {}}
  //         />
  //       );
  //       consumeException(this.props.exception);
  //     }
  //   };

  render() {
    const { isUserAuthenticated } = this.props;
    return (
      
      <Router history={History} store={store}>
        <Switch>
          <Route
            render={(props) => (
              <RouterComponent
                {...props}
                isUserAuthenticated={isUserAuthenticated}
              />
            )}
            path="/"
          />
        </Switch>
        {/* {this.showError()} */}
        {this.showLoading()}
      </Router>
    );
  }
}

function mapStateToProps() {
  return createStructuredSelector({
    isUserAuthenticated: selectors.isUserAuthenticated(),
    isLoading: selectors.isLoading(),
    businessException: selectors.getBusinessException(),
  });
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withReducer = injectReducer({ key: "AppPage", reducer: reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "AppPage", saga: saga });

export default compose(withReducer, withSaga, withConnect)(App);
