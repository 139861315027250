import { ActionTypes } from "../constant/constant";

export function updateIsUserAuthenticated(isUserAuthenticated) {
  return {
    type: ActionTypes.UPDATE_IS_AUTHENTICATED,
    payload: {
      isUserAuthenticated,
    },
  };
}

export function logout() {
  return {
    type: ActionTypes.USER_LOGOUT,
    payload: {},
  };
}

export function getBusinessException(exception) {
  return {
    type: ActionTypes.BUSINESS_EXCEPTION,
    payload: {
      exception,
    },
  };
}
