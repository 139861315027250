import React, { Component } from "react";
import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { divId } = this.props;

    return (
      <div id={divId || "root"}>
        <div className="loader-container">
          {/* <img
            src={constant.LOADER_SPINNER}
            style={{ marginTop: "-36px" }}
            height="80px"
            width="80px"
            alt="loader"
          ></img> */}
          {/* <img
            src="/images/loaders/LoaderSpinner.gif"
            style={{
              position: "absolute",
              right: "50%",
              top: "50%",
              zIndex: 9999,
              width: "120px",
              height: "120px",
            }}
            alt="loader"
          ></img> */}
          <img
            src="/images/loaders/loaderRolling.gif"
            style={{ width: "120px", height: "120px" }}
            alt="loader"
          ></img>
          <div className="animated fadeIn pt-3 text-center">Loading...</div>
        </div>
      </div>
    );
  }
}

export default index;
