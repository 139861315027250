import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Loader from "../components/Helper/Loader";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// ------------------------- Containers -------------------------
const DefaultStructure = React.lazy(() =>
  import("../containers/DefaultStructure")
);

// ------------------------- Pages -------------------------
const OTPVerification = React.lazy(() =>
  import("../containers/OTPVerification")
);
const SignIn = React.lazy(() => import("../containers/SignIn"));
const Page404 = React.lazy(() =>
  import("../theme(DontModifyForReference)/component/Pages/Page404")
);
const Page500 = React.lazy(() =>
  import("../theme(DontModifyForReference)/component/Pages/Page500")
);
const Register = React.lazy(() =>
  import("../theme(DontModifyForReference)/component/Pages/Register")
);

// ------------------------- Paths -------------------------

export const pathsUniversal = [];

export const pathsUnAuthenticated = ["/signin", "/otp-verification"];

export const pathsAuthenticated = [
  "/agent-list",
  "/agent-detail",
  "/course-list",
  "/add-course",
  "/edit-course",
  "/question-list",
  "/add-question",
  "/edit-question",
  "/lead-list",
  "/lead-detail",
  "/sales-list",
  "/sales-detail",
  "/feedback-list",
];

// ------------------------- Router -------------------------

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isRedirectPathExistIn = (paths) => {
    const isExist =
      paths.find((item) => window.location.href.includes(item)) != null;
    return isExist;
  };

  render() {
    const { isUserAuthenticated } = this.props;

    if (window.location.pathname === "/") {
      return <Redirect to="/signin" />;
    }

    if (!this.isRedirectPathExistIn(pathsUniversal)) {
      if (isUserAuthenticated) {
        if (this.isRedirectPathExistIn(pathsUnAuthenticated)) {
          return <Redirect to="/user-management/agent-list" />;
        }
      } else {
        if (this.isRedirectPathExistIn(pathsAuthenticated)) {
          return <Redirect to="/signin" />;
        }
      }
    }

    return (
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/signin"
            name="SignIn"
            render={(props) => <SignIn {...props} />}
          />
          <Route
            path="/otp-verification"
            name="OTPVerification"
            render={(props) => <OTPVerification {...props} />}
          />
          <Route
            path="/user-management"
            name="User Management"
            render={(props) => <DefaultStructure {...props} />}
          />
          <Route
            path="/course-management"
            name="Course Management"
            render={(props) => <DefaultStructure {...props} />}
          />
          <Route
            path="/lead-management"
            name="Lead Management"
            render={(props) => <DefaultStructure {...props} />}
          />
        </Switch>
      </React.Suspense>
    );
  }
}
