import {
  getFetchRequestedAction,
  getFetchCompletedAction,
} from "../configuration/actions/loading";
import { put } from "redux-saga/effects";

export function* loadingInterceptor(actualSaga, action) {
  yield put(getFetchRequestedAction());
  yield actualSaga(action);
  yield put(getFetchCompletedAction());
}
